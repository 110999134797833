.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-size: 14px;
}

.App-link, a {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.secret-key div {
  padding: 40px;
  background-color: rgb(0, 0, 0, 0.5);
  margin-top: 20px;
  border: 1px solid rgb(0, 0, 0, 0.8);
}

.secret-key label {
  display: block;
}

button {
  margin-top: 20px;
}

.secret-key input {
  margin-top: 10px;
  max-width: 400px;
  display: block;
}

.app-info .button {
  padding: 10px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #434343;
  outline: none;
  transition: 1s ease-in-out;
  position: relative;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.app-info .wallet-adapter-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.app-info button .border {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #3AB03E;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.app-info button:hover {
  transition: 1s ease-in-out;
  background: rgb(0, 0, 0, 0.5);
}

.app-info button:active {
  position: relative;
  top: 1px;
}

.app-info {
  margin-bottom: 20px;
}

.options {
  padding: 10px;
}

div.react-confirm-alert-overlay {
  z-index: 9999;
}
